import { z } from 'zod';

import { schoolSchema } from './school_schema';
import { salesAgentSchema } from './sales_agent_schema';
import { teacherAgentSchema } from './teacher_agent_schema';

export const teacherProfileSchema = z.object({
  // 顧客ID
  customerId: z.string().nonempty(),

  // ニックネーム
  nickname: z.string().nonempty(),

  // メールアドレス公開設定（boolean）
  discloseEmail: z.boolean(),

  // 勤務先学校
  school: schoolSchema,

  // 紹介販売店
  salesAgent: salesAgentSchema,

  // 担当教科
  subjectAreas: z.array(
    z.object({
      id: z.string().nonempty(),
      name: z.string().nonempty(),
    }),
  ),

  // 生年月日
  dateOfBirth: z.coerce.date().nullable(),
});

export const userInfoSchema = z.object({
  id: z.string().nonempty(),

  // 姓
  lastName: z.string().nonempty(),

  // 名
  firstName: z.string().nonempty(),

  // 姓（カナ）
  lastNameKana: z.string().nonempty(),

  // 名（カナ）
  firstNameKana: z.string().nonempty(),

  // 登録メールアドレス
  email: z.string().email().nonempty(),

  // 連絡先（勤務先）電話番号
  phoneNumberWork: z.string().nonempty(),

  // 緊急連絡先（個人）電話番号
  phoneNumberPersonal: z.string().nonempty(),

  teacherProfile: teacherProfileSchema,

  // 担当販売店
  teacherAgents: z.array(teacherAgentSchema),
});

export type UserInfo = z.infer<typeof userInfoSchema>;
